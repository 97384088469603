import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
  IconButton,
} from "antd";
import axiosInstance from "../axiosInstance";
import axios from "axios";

require("./otp.css");

function ForgotPasswordModel(props) {
  const [otp, setOtp] = useState("");
  const [password, setpassword] = useState("");
  const [mobile, setEmail] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [MOtp, setMOtp] = useState(false);
  const [Otp, setotp] = useState("okk");
  const [Subb, setSubb] = useState(1);
  const [verificationId, setVerificationId] = useState('');

  useEffect(() => {
    setVerificationId(props.verificationId)
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const onOtp = () => {
    axiosInstance.post("/sms/send", { mobile: mobile }).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Email Sent Fail");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Otp Sent successfully");
        // setIsModalOpen(true);
        setotp("okk");
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
    axiosInstance
      .post("/sms/send", { mobile: props.mobile })
      .then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Otp Sent Fail");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Otp Sent successfully");
        } else message.error("Something wrong. Please try again...!");
      });
  };
  var existMatch = "";
  const onCheakOtp = async () => {
    if (otp.length === 4) {
      const data ={
         mobileNumber: props.mobile,
         verificationId: verificationId,
         customerId: 'C-832FE5D64342432',
         code: otp,
      }
      console.log(data)
             const options = {
                 method: 'GET',
                 url: 'https://cpaas.messagecentral.com/verification/v2/verification/validateOtp',
                 params: {
                   countryCode: '91',
                   mobileNumber: props.mobile,
                   verificationId: verificationId,
                   customerId: 'C-832FE5D64342432',
                   code: otp,
                 },
                 headers: {
                   authToken: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJDLTgzMkZFNUQ2NDM0MjQzMiIsImlhdCI6MTcyMTIyMDAxNCwiZXhwIjoxODc4OTAwMDE0fQ.fT7RuX8RN2eGhGKSVjxStJLHgvrAFYNXrI7AV-1dYVE-UnOPpUr8ClooD2UN-sLAGUqOdBCUOUZvw11qSbpS6w',
                 },
               };
         
               try {
                 console.log("response");
         
                 const response = await axios.request(options);
                 console.log(response.data);
                 if (response.data.responseCode ==200 ) {
                     setMOtp(true)
                     message.success("Otp successfully");
                     setMOtp(true);
        message.success("Otp verify successfully");
        setSubb("okk");
                
              } else if (response.data.responseCode ==505) {
               message.error("please enter valid otp ...!");
              } else message.error("Something wrong. Please try again...!");
               } catch (error) {
                 console.error('Error fetching data:', error);
               }
          
         }
         else {
             message.error("Enter four Digit Otp");
         }
  };
  const onUpdatePassword = async () => {
    const data = {
      password: password,
    };

    if (!!password) {
      await axiosInstance.get("/member").then((response) => {
        existMatch = response.data.data.find((x) => x.mobile === props.mobile);
      });
      if (props.mobile === existMatch.mobile) {
        await axiosInstance
          .put(`/member/${existMatch._id}`, data)
          .then((res) => {
            if (res.data && res.data.responseCode === -1) {
              message.error("Record Already Exists");
            } else if (res.data && res.data.responseCode === 1) {
              message.success("Record updeted successfully");
              props.setIsModalOpen(false);
            } else message.error("Something wrong. Please try again...!");
          });

        // props.setOtp("0kk")
      } else {
        setMOtp(false);
        message.error("Plese Enter Vailid Otp");
      }
    } else {
      message.error("Enter Six Digit Otp");
    }
  };
  return (
    <Modal
      title="Forgot password"
      open={props.isVisible}
      onCancel={props.onCancel}
      width={"500px"}
    >
      <div>
        <div>
          {Subb === 1 && (
            <div>
              {Subb === 1 && <h4>Verify OTP</h4>}
              {Subb === "okk" && <h4>Verify OTP</h4>}

            
              <div style={{ marginBottom: "40px" }}>
              {Otp === "okk" && Subb === 1 && (
                <Input
                  placeholder="Enter 6 digite otp"
                  maxLength={6}
                  minLength={6}
                  value={otp}
                  onChange={({ target }) => {
                    setOtp(target.value);
                  }}
                />
              )}
              </div>
             

              {/* {Otp === "okk" && Subb === 1 && (
                <div className="countdown-text11">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>Didn't recieve code?</p>
                  )}

                  <button
                    disabled={seconds > 0 || minutes > 0}
                    style={{
                      color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                    }}
                    onClick={resendOTP}
                  >
                    Resend OTP
                  </button>
                </div>
              )} */}

              {Subb === 1 && (
                <button className="submit-btn11" onClick={() => onCheakOtp()}>
                  SUBMIT
                </button>
              )}
            </div>
          )}
          {Subb === "okk" && (
            <div>
              <div style={{ marginBottom: "20px" }}>
                <Input
                  placeholder="Enter Your Password"
                  type="password"
                  value={password}
                  onChange={({ target }) => {
                    setpassword(target.value);
                  }}
                />
              </div>
              <button
                className="submit-btn11"
                onClick={() => onUpdatePassword()}
              >
                SUBMIT
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ForgotPasswordModel;
